import React from 'react'
// import SideNav from '../../components/Dashboard/SideNav'
import Dashboard from '../../components/Dashboard/Dashboard'

function NewDashboard() {
  return (
    <div className="flex">
    {/* <SideNav/> */}
    <Dashboard/>
    </div>
  )
}

export default NewDashboard