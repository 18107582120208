import React, {useState} from "react";
// import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "../images/Secure Server-amico.svg";
import logo from "../images/logo.svg";
import { ReactComponent as LockIcon } from "feather-icons/dist/icons/lock.svg";
import {Link} from "react-router-dom"
import {auth} from '../firebase-auth'
import Swal from "sweetalert2";  
import {sendPasswordResetEmail } from "firebase/auth";

const Container = tw(ContainerBase)`min-h-screen bg-primary-500 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0  sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;


const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default function Login(){
  const [logoLinkUrl] = useState("#");
  const [illustrationImageSrc] = useState(illustration);
  const [headingText] = useState("Reset your password");
  const [email,setEmail] = useState("");
  const [submitButtonText,setSubmitButtonText] = useState("Reset Email");
  const [SubmitButtonIcon] = useState(LockIcon);

  function handleSubmit(e){
    e.preventDefault()
    setSubmitButtonText("Loading...") 
    if(email === ""){
        Swal.fire({
            title: 'Request invalid',  
            text: "Please enter your email address to proceed",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Ok'
          })
    setSubmitButtonText("Reset Email") 
    }
    else{
    sendPasswordResetEmail(auth,email)
    .then(()=>{
        Swal.fire({
          title: 'Email sent successfully',  
          text: "Kindly check your inbox/spam for an email from us",  
          icon: 'success',  
          confirmButtonColor: '#eb3f3f',   
          confirmButtonText: 'Ok'
        })
        setSubmitButtonText("Reset Email")
    }).catch((error)=>{
        console.log(error)
      Swal.fire({
          title: 'Password not reset',  
          text: "This email is not associated with any account on Wadi",  
          icon: 'error',  
          confirmButtonColor: '#eb3f3f',   
          confirmButtonText: 'Try again'
        })
       setSubmitButtonText("Reset Email")
    })
}
    }
  
 
return (
    <Container>
      <Content>
        <MainContainer>
          <Link to="/">
          <LogoLink  href={logoLinkUrl}>
            <LogoImage className="mt-12" src={logo} />
          </LogoLink>
          </Link>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form className="mt-6">
                <Input type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
                <SubmitButton onClick={handleSubmit} type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <Link href="https://app.wadi.africa/signup"  tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </Link>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
)
                }
