import React from "react";
import "./ResearchTable.css"


function VisitorsTable(props) {
  

  const checkIndex = (e) =>{
    console.log(e)
    console.log(props)
  }

  function singleProject(project){
    localStorage.setItem('project',JSON.stringify(project))
    window.location.href= "/project/"+project.slug
  }


  return (
    props.projects.map((project,i) =>(
        <tbody className="bg-white" key={i}>
          <tr className="text-gray-700">
            <td className="px-4 py-3" onClick={()=>singleProject(project)}>
              <div className="flex items-center text-sm">
              
                <div>
                  <p
                    style={{ fontSize: "13px", lineHeight: "13px",marginBottom:"4px" }}
                    className="text-black"
                  >
                   {project.title}
                  </p>
                </div>
              </div>
            </td>

            <td className="px-4 py-3 text-xs font-semibold">
              {project.date}
            </td>

            <td className="px-4 py-3 text-xs font-semibold">
              <div className={project.status==="Under Review"?"pending":"completed"}>{project.status}</div>
            </td>



            <td className="px-4 py-3 text-xs">
            <div class="w-full bg-gray-200 rounded-full">
  <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width:parseFloat(project.progress)+"%"}}> {project.progress}%</div>
</div>
            </td>
            <td className="px-4 py-3 text-sm">
              <button className="mr-4" onClick={()=>checkIndex(i)}>
               <i className="fas fa-pen"></i>
              </button>
              <button onClick={() => props.deleteRow(project)}>
               <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
))
  );
}

export default VisitorsTable;
