import { SearchIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import Modal from "react-modal";
import styles from "./Styles"
import { XIcon } from "@heroicons/react/solid";
import AddQuestion from "./AddQuestion"
import { auth } from "../../firebase-auth";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs,orderBy,query } from "firebase/firestore";
import { db } from "../../db";
import { Link } from "react-router-dom";
import Signup from "../../components/auth/Signup"
import moment from "moment";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import wadiLogo from "../../images/logo.svg"
import "./Styles.css"
export default class Forum extends Component{

  // const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [user, setUser] = useState("")
  // const [forum] = useState([])
  
  state = {
    modalIsOpen:false,
    user:"",
    forum:[],
    signupModal:false,
    searchValue:"",
    filterCategory:"",
    loading:true
  }



  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     setUser(user)
  //   });
  // })


  componentDidMount(){
       onAuthStateChanged(auth, (user) => {
      this.setState({user:user})
    });
    this.querySnapshot()
  }

  async querySnapshot() {
    const array = []
    const q = query(collection(db,"forum"), orderBy("timestamp", "asc"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      array.push(doc.data())
      return array
    });
    this.setState({
      forum:array
    })
    this.setState({
      loading:false
    })
  }




   openModal = () => {
     if(this.state.user === null){
       console.log("signup modal",this.state.signupModal)
      this.setState({signupModal:!this.state.signupModal})
     } else{
      console.log("normal modal",this.state.modalIsOpen)
       this.setState({modalIsOpen:!this.state.modalIsOpen})
     }
  }

  closeModal =() =>{
    this.setState({modalIsOpen:!this.state.modalIsOpen})
  }

  closeSignupModal =() =>{
    this.setState({signupModal:!this.state.signupModal})
  }

  filteredSearch = (e) => {
    this.props.history.push('/category/'+e.target.value.toLowerCase())
    this.state.forum.filter(
      (topic)=>topic.category === this.state.filterCategory
    )
  }


  render(){
  
    let filteredForum = this.state.forum.filter(topic =>{
      return topic.topic.toLowerCase().includes(this.state.searchValue.toLowerCase())
    })





  return (
    <div className="bg-gray-50" style={{ height: "1000px" }}>
      <div className="lg:w-4/5 lg:m-auto">
        <div className="lg:flex p-9 justify-between">
          <div className="text-center" style={{marginTop:"-14px"}}>
            <Link to="/">
            <img src={wadiLogo} alt="" width="100px" height="100px" />
            </Link>
            </div>
          <div>
            <select
              id="whomtosee"
              type="text"
              className="mb-5 flex-grow border-0 rounded-full px-20 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              placeholder="Select Category"
              onChange={(e)=>this.filteredSearch(e)}
            >
              <option defaultValue="">
                All Categories
              </option>
              <option>Art</option>
              <option>Energy</option>
              <option>Engineering</option>
              <option>Environment</option>
              <option>Geoscience</option>
              <option>History</option>
              <option>Humanities</option>
              <option>Medicine</option>
              <option>Politics</option>
              <option>Programming</option>
              <option>Social Science</option>
              <option>Scholarships</option>
              <option>Science</option>
              <option>Space</option>
              <option>Technology</option>
            </select>
          </div>

          <div className="relative mb-3">
            <input
              id="search"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="Search topic"
              onChange={(e)=>this.setState({searchValue:e.target.value})}
            >
            </input>
            <SearchIcon style={{ cursor: "pointer" }} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
          </div>

          <div className="flex mb-4">
            <div>
              <button className="ml-4 bg-white rounded-full p-2 px-5 hover:bg-blue-500 hover:text-white">Latest</button>
            </div>

            <div>
              <button className="ml-4 bg-white rounded-full p-2 px-5 hover:bg-blue-500 hover:text-white">Top</button>
            </div>
          </div>

          <div>
            <button onClick={this.openModal} className="rounded-full w-full p-2 px-7 bg-blue-600 hover:bg-blue-500 text-white"><i className="fas fa-plus mr-3"></i>Ask Question</button>
          </div>
        </div>



        <div className="lg:ml-9">
          <div className="flex lg:justify-center lg:flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="lg:overflow-hidden border-b border-gray-200 sm:rounded-lg">

              {this.state.loading &&
                <div style={{width:"100%"}}>
                <div className="px-6 py-4" style={{width:"100%"}}>
                          <div style={{width:"300px"}} bclassName="text-sm text-gray-900">
                            <p className="text-xl font-bold"><Skeleton /></p>
                            <p className="break-normal lg:w-1/2"><Skeleton/></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                                <Skeleton />
                              </div>
                              <div>
                                <p className="ml-2 mt-2 text-sm"><Skeleton /></p></div>
                            </div>
                          </div>
                        </div>

                        <div className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            <p className="text-xl font-bold"><Skeleton /></p>
                            <p className="break-normal lg:w-1/2"><Skeleton/></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                              <Skeleton/>
                              </div>
                              <div>
                                <p className="ml-2 mt-2 text-sm"><Skeleton /></p></div>
                            </div>
                          </div>
                        </div>

                        <div className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            <p className="text-xl font-bold"><Skeleton /></p>
                            <p className="break-normal lg:w-1/2"><Skeleton/></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                              <Skeleton/>
                              </div>
                              <div>
                                <p className="ml-2 mt-2 text-sm"><Skeleton /></p></div>
                            </div>
                          </div>
                        </div>

                        <div className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            <p className="text-xl font-bold"><Skeleton /></p>
                            <p className="break-normal lg:w-1/2"><Skeleton/></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                              <Skeleton/>
                              </div>
                              <div>
                                <p className="ml-2 mt-2 text-sm"><Skeleton /></p></div>
                            </div>
                          </div>
                        </div>

                        <div className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            <p className="text-xl font-bold"><Skeleton /></p>
                            <p className="break-normal lg:w-1/2"><Skeleton/></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                              <Skeleton/>
                              </div>
                              <div>
                                <p className="ml-2 mt-2 text-sm"><Skeleton /></p></div>
                            </div>
                          </div>
                        </div> 
                        </div>
                        }









                  {this.state.forum && filteredForum.reverse().map((person, i) => (
                    <div key={i}>
                      <Link to={"/forum/"+person.slug}>
                      <div className="p-2 shadow-lg rounded-lg mb-3 flex">
                        <div className="px-6 py-4">
                          <div className="items-center">
                            {/* <div className=" text-center">
                              <p>{person.votes}</p>
                              <p>votes</p>
                            </div> */}

                            <div className="text-center text-sm bg-blue-600 rounded-lg  p-4 text-white"> {person.answers.length}<br></br>
                              <span>answers</span>
                            </div>
                            <p style={{fontSize:"8px"}} className="mt-2">{moment(person.timestamp).format("LLL")}</p>
                            <p className="mt-1 text-xs">Category: <br/>{person.category}</p>
                          </div>
                        </div>
                        <div className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            <p className="text-xl font-bold">{person.topic}</p>
                            <p className="break-normal lg:w-1/2" dangerouslySetInnerHTML={{__html: person.description.slice(0,16)+"..."}}></p>
                          </div>
                          <div className="mt-3">
                            <div className="flex">
                              <div>
                                <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                              </div>
                              <div>
                                <p className="ml-2 mt-1 text-sm" style={{marginBottom:"-9px"}}>{person.name}</p>
                                <span className="ml-2" style={{fontSize:"10px"}}>{person.title}</span>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  ))}
                </div>

                <Modal
                  isOpen={this.state.modalIsOpen}
                  appElement={document.getElementById('root') || undefined}
                  onRequestClose={this.closeModal}
                  style={styles.customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="flex p-2 justify-between">
                    <p className="font-bold" style={{ fontSize: "23px" }}>Ask Question</p>
                    <button className="bg-blue-600 rounded-full p-2" onClick={this.closeModal}>
                      <XIcon style={{ color: "white" }} className="text-center w-full w-4 h-4" />
                    </button>
                  </div>
                  <div className="p-2">
                    <AddQuestion user={this.state.user} />
                  </div>
                </Modal>


                <Modal
                  isOpen={this.state.signupModal}
                  appElement={document.getElementById('root') || undefined}
                  onRequestClose={this.closeSignupModal}
                  contentLabel="Signup Modal"
                  style={styles.customStyles}
                >
                  <div className="flex p-2 justify-between">
                    <button className="bg-blue-600 rounded-full p-2" onClick={this.closeSignupModal}>
                      <XIcon style={{ color: "white" }} className="text-center w-full w-4 h-4" />
                    </button>
                  </div>
                  <div className="p-2">
                    <Signup />
                  </div>
                </Modal>


              </div>
            </div>
          </div>


        </div>







      </div>
    </div>
  );
                  }
}
