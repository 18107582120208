import React, { Component } from 'react'
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../../components/headers/light.js";
import tw from "twin.macro";
import {doc,getDoc} from "firebase/firestore"
import { db } from '../../db.js';
import Skeleton from 'react-loading-skeleton';
import Flutterwave from "../../components/Flutterwave/Flutterwave"
import {CheckIcon} from "@heroicons/react/solid"


const buttonRoundedCss = tw`rounded-full`
const Header = tw(HeaderBase)`max-w-none`;
const Input = tw.input`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow-sm text-sm focus:outline-none focus:border-white focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow-sm text-sm focus:outline-none focus:border-white focus:bg-white mt-5 first:mt-0`;

const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/forum">Wadi Forum</NavLink>
      <NavLink href="/navigator">Wadi Geolocator</NavLink>
      <NavLink href="/academy">Wadi Academy</NavLink>
      <NavLink href="/#testimonials">Testimonials</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} href="/signup">
        Sign Up
      </PrimaryLink>
    </NavLinks>
  ];

export class Course extends Component {
    state = {
        course:{},
        loading:true,
        name:"",
        email:"",
        currency:"",
        amount:5000,
        benefits:[]
    }

    componentDidMount(){
        this.fetchData()
      }

    async fetchData(){
        const courseRef = doc(db,'academy',window.location.pathname.slice(9))
        const response = await getDoc(courseRef)
        this.setState({course:response.data()})
        console.log(this.state.course.benefits)
        this.setState({benefits:this.state.course.benefits})
        this.setState({loading:false})
    }

    setAmount(e){
     this.setState({currency:e.target.value})
     if(e.target.value === "USD"){
      this.setState({amount:10})
     } 
     else if(e.target.value === "GBP"){
      this.setState({amount:8})
     }

     else if(e.target.value === "EUR"){
      this.setState({amount:9})
     }
     else{
      this.setState({amount:5000})
     }
    }


  render() {
    return (
      <div>
          <div className="my-4 mx-3">
          <Header links={navLinks} className="lg:px-8" />
          </div>

          <div className="lg:mx-5 lg:flex lg:justify-between">
              <div className="lg:w-1/2">
                  <div className="mx-3">
                  {this.state.loading && <Skeleton height={300}/>}
                  </div>
              {!this.state.loading && <img src={this.state.course.image} alt="#" style={{width:"100%",borderRadius:"16px"}}/>}
              </div>

              <div className="textPosition lg:w-1/2 mx-3">
              {this.state.loading && <Skeleton count={2} />}
                  <h2 className="mx-2 text-2xl font-bold">{this.state.course.title}</h2>
                  <div className="mx-2 flex">
                    <div>
                      {this.state.loading && <Skeleton borderRadius={20}/>}
                   {!this.state.loading && <img className="rounded-full w-12 h-12 mr-2 inline-block ring-2 ring-white" src={this.state.course.instructorImage} alt="instructor"/>}
                    </div>
                    <div>
                      <p className="instructorClass">{this.state.course.instructorName}</p>
                      <div className="instructorTitle">Instructor</div>
                    </div>
                    </div>
                  <p className="mx-2 mt-3">{this.state.course.description}</p>
                  <div className="mt-4 mx-2">
                    <h2 className="m-0 p-0 text-2xl font-bold">Benefits:</h2>
                  {this.state.benefits.map((benefit,i)=>(
                    <div key={i}>
                    <p className="flex"><span><CheckIcon className="w-4 h-4 mt-1 mr-2"/></span><span>{benefit.name}</span></p>
                    </div>
                  ))}
                   </div>
                  {this.state.loading && <Skeleton count={4} />}
                  {!this.state.loading && <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="my-9 w-full bg-blue-600 text-white rounded-xl p-4 my-3 shadow-lg hover:bg-blue-100 hover:text-blue-600">Pay {this.state.course.currency} {this.state.course.amount} ($ {(this.state.course.amount/500).toFixed(0)})</button>}
              </div>
          </div>


          <button type="button"
          id="modalButton"
          style={{display:"none"}}
  className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
  data-bs-toggle="modal">
  Launch static backdrop modal
</button>


<div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
  id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="mx-3 modalWidth modal-dialog relative w-auto pointer-events-none">
    <div
      className="modal-content border-none shadow-sm relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div
        className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Register for the Research Masterclass
        </h5>
        <button type="button"
          className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body relative p-4">
            <Input
            type="text"
            id="text"
            onChange={(e)=>this.setState({name:e.target.value})}
            placeholder="Name"
          ></Input>

            <Input
            type="email"
            id="email"
            onChange={(e)=>this.setState({email:e.target.value})}
            placeholder="Email"
          ></Input>

          <Select
            type="text"
            id="currency"
            onChange={(e)=>this.setAmount(e)}
            placeholder="Currency"
            label="Choose Currency"
          >
              <option defaultValue="NGN">Choose Currency</option>
              <option >NGN</option>
              <option>USD</option>
            

          </Select>

          <Flutterwave buttonName={"Complete Registration"}  userData={{name:this.state.name,email:this.state.email,currency:this.state.currency,amount:this.state.amount}} />
      </div>
      <div
        className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
        <button 
          className="inline-block px-6 py-2.5 bg-white outlined border text-blue-600 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
          data-bs-dismiss="modal">Close</button>
    
      </div>
    </div>
  </div>
</div>


      </div>
    )
  }
}

export default Course