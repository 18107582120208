const customStyles = {
    overlay:{
      backgroundColor: 'rgba(52, 52, 52, 0.8)'
      
    },
  
    content:
    {
      position:"fixed",
      bottom:0,
      margin:'auto',
      borderRadius:"60px 60px 0px 0px",
      left:0,
       width:"100%",
      // height:"570px",
      zIndex:20
    },
  };


  const styles= {
     customStyles:customStyles
  }

  export default styles