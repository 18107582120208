import React from 'react'
import { FaCheck } from 'react-icons/fa'
import './SteppersForm.css'



function ReviewForm(props) {
  return (
    <div>
    <div>
  <div className="col-xs-12 col-md-8 offset-md-2 block">
    <div className="wrapper-progressBar">
      <ul className="progressBar">
        <li className="active"><div className="numberPosition">{props.step>1?<FaCheck className="mt-1 -ml-1"/>:<span className="onePosition">1</span>}</div><span className="text-black"> Project Details </span></li>
        <li className={props.step>=2?'active':''}><div className="numberPosition">{props.step>=2?<FaCheck className="mt-1"/>:'2'}</div> <span className="text-black">Payment</span></li>
        <li className={props.step>=3?'active':''}> <div className="numberPosition">{props.step>=3?<FaCheck className="mt-1"/>:'3'}</div> <span className="text-black"> Review-in-progress </span></li>
        <li className={props.step>=4?'active':''}> <div className="numberPosition">{props.step>=4?<FaCheck className="mt-1"/>:'4'}</div><span className="text-black"> Manuscript Ready</span></li>
      </ul>
    </div>
  </div>
</div>

</div>
  )
}

export default ReviewForm