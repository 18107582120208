import { ShoppingCartIcon } from "@heroicons/react/solid";
import React from "react";
import {NavLink} from "react-router-dom"

function ProductCard(props) {
  return props.products.map((product, i) => (
    <div key={i}>
        <NavLink to={"/product/"+i} >
        <div className="flex">
      <div className="flex p-2">
        <div style={{ width: "200px" }} className="hover:shadow-2xl rounded-lg p-3 shadow-lg">
          <img
            className="rounded-lg"
            src={product.image}
            alt=""
            height="60%"
            width="100%"
          />
          <p className="text-lg font-bold">{product.name}</p>
          <p className="text-md font-medium">NGN {product.price}</p>
          <div  className="flex justify-end mb-3">
            <button className="flex justify-end bg-blue-600 hover:bg-blue-200 focus:bg-blue-200 text-white p-3 rounded-lg">
              <ShoppingCartIcon className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
    </NavLink>
    </div>
  ));
}

export default ProductCard;
