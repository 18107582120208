import React,{useState} from 'react'
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../components/headers/light";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import TwoColWithButton from '../components/features/TwoColWithButton';
const Header = tw(HeaderBase)`max-w-none`;

function Shop() {
    const [buttonRounded] = useState(true)
    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
        <NavLinks key={1}>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/shop">Shop</NavLink>
          <NavLink href="/forum">Wadi Forum</NavLink>
          <NavLink href="https://app.wadi.africa">Wadi Navigator</NavLink>
          <NavLink href="/#testimonials">Testimonials</NavLink>
        </NavLinks>,
        <NavLinks key={2}>
          <NavLink href="https://app.wadi.africa/login" tw="lg:ml-12!">
            Login
          </NavLink>
          <PrimaryLink css={buttonRoundedCss} href="https://app.wadi.africa/signup">
            Sign Up
          </PrimaryLink>
        </NavLinks>
      ];

    return (
        <div>
            <Header links={navLinks} className="lg:px-12 p-6" />
            <div className="mb-12">
              <TwoColWithButton />
            </div>
            <Footer />
        </div>
    )
}

export default Shop
