import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function WhatsAppButton() {
  return (
    <button
      onClick={() =>
        window.open(
          "https://api.whatsapp.com/send?phone=2348133416455&text=Hi%20Wadi.%20I%20would%20like%20to%20inquire%20about%20your%20products%20and%20services.%20My%20name%20is%20",
          "_blank"
        )
      }
      className="whatsappbutton animate-float"
    >
      <FaWhatsapp color={"white"} size={35} />
    </button>
  );
}

export default WhatsAppButton;
