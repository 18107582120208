import React,{useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../../components/headers/light.js";
import { SectionHeading } from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "../../components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "../../images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/dot-pattern.svg"
import { Link } from "react-router-dom";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-blue-900 xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-blue-600`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-blue-700 text-white font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-orange-600 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const Quote = tw.blockquote``
const CustomerName = tw.p`mt-4 font-bold`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`


export default function TwoColumnWithFeaturesAndTestimonials() {
  const [heading] = useState("AI-Powered tools for African Researchers");
  const [description] = useState("At Wadi, we are committed to closing the research gap in Africa by connecting researchers of all kinds to the resources they need. ");
  const [imageSrc] = useState("https://medlabstudyhall.com/wp-content/uploads/2021/09/pexels-gustavo-fring-8770727-scaled.jpg");
  const [imageDecoratorBlob] = useState(true);
  const [primaryButtonUrl] = useState("https://app.wadi.africa/signup");
  const [primaryButtonText] = useState("Get Started");
  const [buttonRounded] = useState(false);
  const [features] = useState(["Find equipment","Book Labs", "Online Forum and Community","Research Funding and Scholarship information"]);
  const [testimonial] = useState({
    quote: "Currently, Africa is the second largest continent in the world, but it contributes less than 1% to global research.",
    customerName: "",
    customerCompany: "Elsevier.com"
  });

  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/forum">Wadi Forum</NavLink>
      <NavLink href="/navigator">Wadi Geolocator</NavLink>
      <NavLink href="/academy">Wadi Academy</NavLink>
      <NavLink href="/#testimonials">Testimonials</NavLink>
      <NavLink href="/publishit">Publish It</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="https://app.wadi.africa/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} href="https://app.wadi.africa/signup">
        Sign Up
      </PrimaryLink>
    </NavLinks>
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <button className="px-8 lg:ml-3 border mt-4  py-3 font-bold rounded-full bg-white shadow-lg text-blue-600 hover:bg-blue-100">POWERED WITH AI ✨</button>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
              <Link to="/navigator">
              <button className="px-8 lg:ml-3 border mt-4  py-4 font-bold rounded-lg bg-white text-blue-600 hover:shadow-2xl hover:text-blue-600 hover:bg-blue-100" onClick={()=>window.open("https://www.gpt.wadi.africa/wadigpt/?token=researcher","_blank")}>
                Try WadiGPT ✨<i className="ml-14 fas fa-arrow-right"></i>
              </button>
              </Link>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} alt="image"  style={{borderRadius:"19px",width:"465px",height:"600px",objectFit:"cover"}} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <QuotesLeftIcon/>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
