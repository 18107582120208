import React from 'react'
import "./AssessmentForm.css"




function PaymentForm(props) {
  return (
    <div id={props.id}>
        <div className="pt-6" >
            <div className="mx-auto max-w-lg">
           <h2 className="pt-6 text-2xl text-center font-bold">{props.title}</h2>
            <p className=" text-center  text-md">{props.description}</p>
            <button className="mt-7 my-5 rounded-xl shadow-lg text-white bg-blue-600 w-full rounded-xl px-8 py-4">Download Manuscript</button>
            </div>
        </div>
    </div>
  )
}

export default PaymentForm