import React,{Component} from "react";







export default class Navigator extends Component {

    state = {
       labs:[],
       searchLabs:"",
       latitute:"",
       longitude:""
    }

    componentDidMount(){
     this.getLocation()
   }



   getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((e)=>(
      localStorage.setItem("location",JSON.stringify({latitude:e.coords.latitude,longitude:e.coords.longitude}))
        ));
        } 
  }


 
  

   filteredSearch = (e) => {
    this.props.history.push('/category/'+e.target.value.toLowerCase())
    this.state.forum.filter(
      (topic)=>topic.category === this.state.filterCategory
    )
  }



render(){
    
  return (
<div>
    <div className="bg-blue-600 py-6 p-3">
      <div className="text-center py-8 px-2">
        <p className="font-extrabold text-white text-3xl">
          Signup for our Upcoming Wadi Event below:
        </p>
        
      </div>
    </div>
    <div className="mt-6 lg:flex lg:justify-center">
 </div>
</div>
  );
}
}
