import React,{useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading} from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
// import TeamIllustrationSrc from "../../images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern } from "../../images/dot-pattern.svg"
import StarRating from "./StarRating.js";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow-xl`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`md:text-left`;
const Heading = tw(
  SectionHeading
)` font-black text-left text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight`;
const Description = tw.p`mt-4  md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 w-40 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-lg`
]);


export default function TwoColWithButton(props){
  const [heading] = useState((
    <>
      Plaintain Powder
    </>
  ))
  const [productNo,setProductNo] = useState(1)

  const [description] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.")
  const [primaryButtonText] = useState("ADD TO CART")
  const [primaryButtonUrl] = useState("")
  const [imageSrc] = useState("https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg")
  const [buttonRounded] = useState(true)
  const [imageRounded]= useState(true)
  const [imageBorder] = useState(false)
  const [imageShadow] = useState(false)
  const [imageCss] = useState(null)
  const [imageDecoratorBlob] = useState(false)
  const [imageDecoratorBlobCss] = useState(null)
  const [textOnLeft] = useState(true)


  function decreaseProduct(){
    if(productNo !==1){
    setProductNo(productNo-1)
    }
  }

  function increaseProduct(){
    setProductNo(productNo+1)
  }


  return (
    <Container className="px-6">
      <TwoColumn>
        <ImageColumn textOnLeft={textOnLeft}>
          <Image css={imageCss} className="shadow-2xl" src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn className="mt-4 flex justify-start">
          <TextContent className="justify-start">
            <Heading>{heading}</Heading>
            <div className="flex my-3 justify-start">
            <StarRating />
            </div>
            <p className="text-2xl font-bold">
              NGN 500
            </p>
            <Description>{description}</Description>
            <div className="flex justify-between">
            <div >
            <PrimaryButton buttonRounded={buttonRounded} href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
            </div>

            <div className="ml-3 border w-13 rounded-lg  p-3 mt-8 flex">
             <MinusIcon onClick={decreaseProduct} className="w-3 h-3"/><p style={{marginTop:"-5px"}} className="mx-4">{productNo}</p>
             <PlusIcon onClick={increaseProduct} className="w-3 h-3"/>
                      </div>
                      </div>
          
            {/* Review form */}
            <div className="mt-9">
              <p className="text-2xl font-medium">Leave a review</p>
            <textarea className="w-full h-32 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"></textarea>
            <button className="p-3 mt-3 rounded-lg shadow-md hover:shadow-xl w-full bg-blue-600 hover:bg-blue-200 text-white hover:text-blue-600">Submit Review</button>
            </div>
  </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
