
import React
// ,
// {useState} 
from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
// import { 
//   // AiOutlineOrderedList,AiOutlineLine, 
//   AiOutlineStrikethrough } from "react-icons/ai"
// import {BsBlockquoteRight,BsCodeSlash} from "react-icons/bs"
import {FaHeading} from "react-icons/fa"
import {CgArrowsBreakeV} from "react-icons/cg"
import {MdOutlineUndo,MdOutlineRedo
  // ,MdFormatListBulleted
} from "react-icons/md"
import Link from '@tiptap/extension-link'
import "./styles.css"
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Highlight from '@tiptap/extension-highlight'
import { CircleSpinner } from "react-spinners-kit";




const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  function boldContent(e){
    e.preventDefault()
    editor.chain().focus().toggleBold().run()
  }
  function italicContent(e){
    e.preventDefault()
    editor.chain().focus().toggleItalic().run()
  }

  // function strikeContent(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleStrike().run()
  // }

  // function codeContent(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleCode().run()
  // }

  function paragraph(e){
      e.preventDefault()
      editor.chain().focus().setParagraph().run()
  }
  function heading1(e){
        e.preventDefault()
        editor.chain().focus().toggleHeading({ level: 1 }).run()
  }

  function heading2(e){
    e.preventDefault()
    editor.chain().focus().toggleHeading({ level: 2 }).run()
  }

  function heading3(e){
    e.preventDefault()
    editor.chain().focus().toggleHeading({ level: 3 }).run()
  }

  function heading4(e){
    e.preventDefault()
    editor.chain().focus().toggleHeading({ level: 4 }).run()
  }

  // function heading5(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleHeading({ level: 5 }).run()
  // }

  // function heading6(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleHeading({ level: 6 }).run()
  // }

  // function bullet(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleBulletList().run()
  // }
  // function ordered(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleOrderedList().run()
  // }

  // function codeBlock(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleCodeBlock().run()
  // }

  // function blockQuote(e){
  //   e.preventDefault()
  //   editor.chain().focus().toggleBlockquote().run() 
  // }

  // function line(e){
  //   e.preventDefault()
  //   editor.chain().focus().setHorizontalLine().run()
  // }

  function hardBreak(e){
      e.preventDefault()
      editor.chain().focus().setHardBreak().run()
  }

  function undo(e){
    e.preventDefault()
    editor.chain().focus().undo().run()
  }

  function redo(e){
    e.preventDefault()
    editor.chain().focus().redo().run()
  }

  return (
    <>
      <button
        style={{border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
        onClick={(e) => boldContent(e)}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <strong>B</strong>
      </button>
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => italicContent(e) }
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        I
      </button>
      {/* <button
       style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => strikeContent(e)}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <AiOutlineStrikethrough/>
      </button> */}
      {/* <button
         style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => codeContent(e)}
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        code
      </button> */}
      {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </button>
      <button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </button> */}
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => paragraph(e)}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
          <i>P</i>
      </button>
      <button
       style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => heading1(e)}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        <FaHeading />
      </button>
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => heading2(e)}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <i>H2</i>
      </button>
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
        onClick={(e) => heading3(e)}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        <i>H3</i>
      </button>
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => heading4(e)}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
       <i>H4</i> 
      </button>
      {/* <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => heading5(e)}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        <i>H5</i>
      </button>
      <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => heading6(e)}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >
        <i>H6</i>
      </button> */}
      {/* <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 14px"}}
        onClick={(e) => bullet(e)}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <MdFormatListBulleted />
      </button> */}
      {/* <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
        onClick={(e) => ordered(e)}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
       <AiOutlineOrderedList />
      </button> */}
      {/* <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
        onClick={(e) => codeBlock(e)}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        <BsCodeSlash />
      </button> */}
      {/* <button
        style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
        onClick={(e) => blockQuote(e)}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
       <BsBlockquoteRight />
      </button> */}
      {/* <button 
      style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
      onClick={(e) => line(e)}>
       <AiOutlineLine />
      </button> */}
      <button
       style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
       onClick={(e) => hardBreak(e)}>
        <CgArrowsBreakeV />
      </button>
      <button 
      style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
      onClick={(e) => undo(e)}>
        <MdOutlineUndo />
      </button>
      <button
      style={{marginLeft:"10px",border:"solid 1px #ccc", borderRadius:"10px",padding:"3px 10px"}}
      onClick={(e) => redo(e)}>
        <MdOutlineRedo />
      </button>
    </>
  )
}

export default function Editor(props) {
  // const [content,setEditorContent] = useState();
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2,3,4,5,6],
        },
      }),
      Document,
      Paragraph,
      Text,
      Highlight,
      Link.configure({
        linkOnPaste: true
      })
    ],
    content: `
      <p>
        Type here...
      </p>
    `,
    onUpdate({ editor }) {
      // setEditorContent(editor.getHTML());
      props.setDescription(editor.getHTML());
    },
  })

  const submit = (e) => {
    e.preventDefault();
    props.submitQuestion(e)
  }


  return (
    <div>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} 
      id="description"
      type="text"
      className="mb-5 border-0 px-3 py-3 pb-12 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      placeholder="Describe your question"
      style={{heigth:"500px",maxHeight:"190000px"}}
      />

      
<div style={{marginTop:"260px",marginBottom:"400px"}} className="sticky bottom-12 left-0 right-0 text-center">
                    <button
                      className="flex  rounded-lg bg-blue-600 p-3 text-white"
                      onClick={submit}
                    >
                       <CircleSpinner loading={props.loading} /> 
                    <span className="mx-4 justify-center">Submit </span>
                    </button>
                  </div>
    </div>
  )
}