import React,{useState,useEffect} from 'react';
import { getDoc,doc} from 'firebase/firestore'
import {db} from "../../db"
import {LocationMarkerIcon} from "@heroicons/react/solid"
import Modal from 'react-modal';
import styles from "./Styles"
import {XIcon} from '@heroicons/react/solid'
import BookingForm from "./BookingForm"

function BookLab(props) {

  const [loader,setLoader] = useState(true)
  const [lab,setLab] = useState({})
  const [modalIsOpen,setOpen] = useState(false)
  const [ModalTitle,setModalTitle] = useState("")

  
  useEffect(()=>{
    const docRef = doc(db, "labs",window.location.pathname.slice(1));
     getDoc(docRef).then((response)=>{
       setLab(response.data())
       setLoader(false)
     })
 },[])


 function closeModal(){
  setOpen(false)
}

// function openBookingModal(){
//   setModalTitle('Book Lab') 
//   setOpen(!modalIsOpen)
// }

// function rentModal(){
//   setModalTitle('Rent Equipment') 
//   setOpen(!modalIsOpen)
// }

function sendSamplesModal(){
  setModalTitle('Send Sample')
  setOpen(!modalIsOpen)
}

function goBack(){
  props.history.goBack()
}



  return (
  <div>

    { !loader &&
    
    <div>
      <div className="p-4 lg:w-1/2 m-auto mt-10">
        <button onClick={goBack}><i className="fas fa-arrow-left mr-2"></i>Back</button>
       <p className="font-extrabold text-3xl">{lab.name}</p>
       <p>{lab.description}</p>
       <p className="flex mt-2"><LocationMarkerIcon className="w-6 h-6"/> {lab.location}</p>
      
       <button onClick={sendSamplesModal} className="w-full outlined bg-blue-600 text-white rounded-xl p-4 my-3 shadow-lg hover:bg-blue-100 hover:text-blue-600">Send Samples</button>

       <img src={lab.image} className="rounded-lg mt-4" alt="#" />
      </div>

      <div>
      </div>
      </div>
}



<Modal
                  isOpen={modalIsOpen}
                  appElement={document.getElementById('root') || undefined}
                  onRequestClose={closeModal}
                  style={styles.customStyles}
                  contentLabel="Example Modal"
                  
                >
                  <div className="flex p-7 justify-between">
                    <h1 className="font-bold" style={{ fontSize: "23px" }}>{ModalTitle}</h1>
                    <button className="bg-blue-600 rounded-full p-2" onClick={closeModal}>
                      <XIcon style={{ color: "white" }} className="text-center w-full w-4 h-4" />
                    </button>
                  </div>
                  <div className="p-2">
                    {/* <AddQuestion user={this.state.user} /> */}
                    <BookingForm data={lab} />
                  </div>
                </Modal>

  </div>
  )
}

export default BookLab;
