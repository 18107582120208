import React,{Component} from "react";
import tw from "twin.macro";
import { SearchIcon } from "@heroicons/react/solid";
import { query,collection,getDocs } from "firebase/firestore";
import {db} from "../../db"
import Labs from "./Component/Courses"
import LoadingOverlay from "react-loading-overlay";

const Input = tw.input`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow-xl text-sm focus:outline-none focus:border-white focus:bg-white mt-5 first:mt-0`;





export default class Navigator extends Component {

    state = {
       courses:[],
       search:"",
       loading:true
    }

    componentDidMount(){
     this.querySnapshot()
     this.getLocation()
   }



   getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((e)=>(
      localStorage.setItem("location",JSON.stringify({latitude:e.coords.latitude,longitude:e.coords.longitude}))
        ));
        } 
  }


 
   async querySnapshot() {
     const array = []
     const q = query(collection(db,"academy"))
     const querySnapshot = await getDocs(q);
     querySnapshot.forEach((doc) => {
       // doc.data() is never undefined for query doc snapshots
       array.push(doc.data())
       return array
     });
     this.setState({
       courses:array
     })
     this.setState({
       loading:false
     })
   }

   filteredSearch = (e) => {
    this.props.history.push('/category/'+e.target.value.toLowerCase())
    this.state.forum.filter(
      (topic)=>topic.category === this.state.filterCategory
    )
  }



render(){
    let filteredCourses = this.state.courses.filter(course =>{
        return course.title.toLowerCase().includes(this.state.search.toLowerCase())
      })


  return (
    <LoadingOverlay 
    active={this.state.loading}
    spinner
    text='Loading...'
    >
<div>
    <div className="bg-blue-600 py-6 p-3">
      <div className="text-center py-8 px-2">
        <p className="font-extrabold text-white text-3xl">
          Take your research skills to the next level
        </p>
        <div className="mt-6 text-center m-auto relative lg:w-1/2 mb-3">
          <Input
            type="text"
            id="text"
            onChange={(e)=>this.setState({search:e.target.value})}
            placeholder="Search for course"
          ></Input>
          <SearchIcon
            style={{ color:"white",cursor: "pointer",padding:"7px",borderRadius:"50%",background:"blue" }}
            className="w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-3"
          />
        </div>
      </div>
    </div>
    <div className="mt-6 lg:flex lg:justify-center">
 {this.state.courses && <Labs data={filteredCourses}/>}
 </div>
</div>
</LoadingOverlay>
  );
}
}
