import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import { auth } from "../../firebase-auth";
import './Style.css'
import './AddProject.css'
import Services from "./Component/Services"
import disseration from "../../images/dissertation.png"
import review from "../../images/review.jpg"

function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
 
  const { users } = useSelector((state) => state.users);
  const [services] = useState([
    {title:"I want to convert my dissertation to a research manuscript",image:disseration,slug:"dissertation"},
    {title:"I want to review my research manuscript for publishing",image:review,slug:"add-project"}
  ])





  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.push("/login");
      }
    });
  });



  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        users={users}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          users={users}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="my-8">
          <p className="text-center font-bold text-xl">Choose a service:</p>

          <div className="formContainer">
          <div className="mt-6 lg:flex lg:justify-center">
<Services data={services}/>
 </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
