import EventLandingPage from "../../demos/EventLandingPage"


const PublishItApp = () => {
 



  return (
    <div>
      <EventLandingPage />
      

     
    </div>
  );
};

export default PublishItApp;
