
import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";
import stackIcon from "../../../images/bi_stack.svg"
import {  updateDoc, doc,arrayUnion } from "@firebase/firestore";
import {getStorage,ref,getDownloadURL,uploadBytesResumable} from "firebase/storage"
import { db } from "../../../db";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import axios from "axios"
import moment from "moment";
import slugify from "slugify";



const FormContainer = tw.div`w-full flex-1 mt-8 pt-4`;
const Form = tw.form`max-w-xl`;
const Input = tw.input`w-full rounded-xl px-3 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow text-sm focus:outline-none focus:border-white focus:bg-white first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

function DetailsForm(props) {
  const [title,setTitle] = useState(props.projectDetails?props.projectDetails.title:"")
  const [field,setField] = useState(props.projectDetails?props.projectDetails.title:"")
  const [journal,setJournal] = useState("")
  const [plan,setPlan] = useState("")
  const [active,setActive] = useState(false)
  const [step,setStep] = useState(2)
  const [file,setFile] = useState("")
  const [fileLink,setFileLink] = useState("")
  const [progressLoading,setProgressLoading] = useState("")
  const payLoad = {title:title,field:field,journal:journal,plan:plan,document:document}




  function uploadFile(file){
    console.log("the file in the function",file)
    const storage = getStorage()
   // Create the file metadata
/** @type {any} */
// const metadata = {
//   contentType: 'image/jpeg'
// };
    const storageRef = ref(storage, 'files/' + title);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
(snapshot) => {
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  setProgressLoading(progressLoading)
  console.log('Upload is ' + progress + '% done');
  // switch (snapshot.state) {
  //   case 'paused':
  //     console.log('Upload is paused');
  //     break;
  //   case 'running':
  //     console.log('Upload is running');
  //     break;
  // }
}, 
(error) => {
  // switch (error.code) {
  //   case 'storage/unauthorized':
  //     break;
  //   case 'storage/canceled':
    
  //     break;
  //   case 'storage/unknown':
    
  //     break;
  // }
}, 
() => {
  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    console.log('File available at', downloadURL);
    setFileLink(downloadURL)

    const docRef = doc(db,'users',localStorage.getItem("wadiKey"));
    const date = moment(Date.now()).format('LLL')
    const slug = slugify(title,{
      replacement:"-",
      remove: /[$*_+~.()'"!?\-:@]/g,
      lower: true,
    })

    const dissertationSlug = "dissertation/"+slug

    const payLoad = {
      title: title,
      slug:dissertationSlug,
      field:field,
      journal: journal,
      date:date,
      file:fileLink,
      status:"Under Review",
      progress:"10",
      step: step,
    }
   
    updateDoc(docRef,{
      projects:arrayUnion(payLoad)
    
    }).then(() => {
      console.log(props)
      axios({
        method:"POST",
        url:"https://wadi-api.herokuapp.com/mail",
        data:{
        name:"Wadi Admin",
       email:"wadiafrica@gmail.com",
      replyTo:props.users.email,
       title:"New Dissertation Submission Received!",
       template:`<h2>Hi Admin</h2> <p>${props.users.firstName +" "+ props.users.lastName} has sent their manuscript for Preliminary review on Wadi. Here is the link: ${fileLink}. They chose the ${plan} so you know what that means. <br/><br/><p>Kind regards <br/>Team Wadi</p>`
        }
      })
      setActive(false)
      
      props.nextStep(2)

      setStep(2)
    }).catch((error)=>{
      setActive(false)
      Swal.fire({
       title: 'Something went wrong!',  
       text: error.message,  
       icon: 'error',  
       confirmButtonColor: '#eb3f3f',   
       confirmButtonText: 'Ok'
      })
    })


     }).catch((error)=>{
       console.log(error)
       setActive(false)
       Swal.fire({
        title: 'Something went wrong!',  
        text: "Network issue, maybe. Please try again",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
       })
     })
  })
  }




 async function submitForm(e){
    console.log(payLoad)
    e.preventDefault()
    setActive(true)
    if(title === ""){
      Swal.fire({
        title: 'Please enter a title',  
        text: "You need to enter your manuscript title",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      console.log('ah',props)
      setActive(false)
      
    }
    else if(field=== ""){
      Swal.fire({
        title: "Please select your project field!",  
        text: "You need to enter your project field",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      setActive(false)
    }
    else if(journal === ""){
      Swal.fire({
        title: 'Please select a journal',  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      setActive(false)
    }
   else{
      await uploadFile(file)
      
    }
  }


  


    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");
      
        dropZoneElement.addEventListener("click", (e) => {
          inputElement.click();
        });
      
        inputElement.addEventListener("change", (e) => {
          console.log(e.target.files[0])
          setFile(e.target.files[0])
          console.log(file)
          if (inputElement.files.length) {
            updateThumbnail(dropZoneElement, inputElement.files[0]);
          }
        });
      
        dropZoneElement.addEventListener("dragover", (e) => {
          e.preventDefault();
          dropZoneElement.classList.add("drop-zone--over");
        });
      
        ["dragleave", "dragend"].forEach((type) => {
          dropZoneElement.addEventListener(type, (e) => {
            dropZoneElement.classList.remove("drop-zone--over");
          });
        });
      
        dropZoneElement.addEventListener("drop", (e) => {
          e.preventDefault();
      
          if (e.dataTransfer.files.length) {
            inputElement.files = e.dataTransfer.files;
            updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
           
          }
      
          dropZoneElement.classList.remove("drop-zone--over");
        });
      });
      
      /**
       * Updates the thumbnail on a drop zone element.
       *
       * @param {HTMLElement} dropZoneElement
       * @param {File} file
       */
      function updateThumbnail(dropZoneElement, file) {
        let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");
      
        // First time - remove the prompt
        if (dropZoneElement.querySelector(".drop-zone__prompt")) {
          dropZoneElement.querySelector(".drop-zone__prompt").remove();
        }
      
        // First time - there is no thumbnail element, so lets create it
        if (!thumbnailElement) {
          thumbnailElement = document.createElement("div");
          thumbnailElement.classList.add("drop-zone__thumb");
          dropZoneElement.appendChild(thumbnailElement);
        }
      
        thumbnailElement.dataset.label = file.name;
      
        // Show thumbnail for image files
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
      
          reader.readAsDataURL(file);
          reader.onload = () => {
            thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
          };
        } else {
          thumbnailElement.style.backgroundImage = null;
        }


      }
      


  const [submitButtonText] = useState("Next");
  return (
    <LoadingOverlay id="detailForm" active={active} spinner text="Loading...">
      <>.</>
    <>
    <FormContainer id={props.id}>
      <Form className="mt-4">
        <div>
          <label>Manuscript Title</label>
          <Input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" id="title" />
        </div>

        <div className="my-3">
          <label>Research Field</label>
          <select
             value={field}
            onChange={(e)=>setField(e.target.value)}
            id="equipment"
            type="text"
            className="border-0 px-3 py-5 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Select Equipment"
          >
            <option defaultValue="">
              Choose Category
            </option>

            <option value="earth-science">
              Earth science
            </option>

            <option value="life-science">
              Life science
            </option>

            <option value="chemistry">
              Chemistry
            </option>

            <option value="engineeering">
              Engineering
            </option>
          </select>
        </div>


        <label>Journal</label>
        <Input value={journal} onChange={(e)=>setJournal(e.target.value)} type="text" id="journal" />

        <div className="my-3">
        <label>Select Plan</label>
        <select
          onChange={(e)=>setPlan(e.target.value)}
          id="equipment"
          type="text"
          className="border-0 px-2 py-5 placeholder-gray-500 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Intended journal you would like to publish to"
        >
          <option defaultValue="" >
          Select Plan
          </option>

          <option value="regular">
           Regular (Duration: 21 days, NGN 50,000)
          </option>

          <option value="express">
           Express (Duration: 14 days, NGN 60,000)
          </option>
        </select>

        </div>

        <div className="my-3">
        <label>Upload Manuscript</label>
        </div>

        <div className="drop-zone">
          {file ==="" && <div>
            <div className="flex justify-center">
            <img alt=""  src={stackIcon} />
            </div>
            <h2 className="text-center font-bold">Upload Document</h2>
    <span className="text-xs">Drop file here or click to upload</span>
    </div>}
    <input type="file" name="myFile" className="drop-zone__input" />
    
    {progressLoading!==""&&<p>Upload Progress:{progressLoading}%</p>}

  </div>


        



        <SubmitButton onClick={submitForm} type="submit">
          <span className="text">{submitButtonText}</span>
          <FaArrowRight className="ml-7" />
        </SubmitButton>
      </Form>
    </FormContainer>
    </>
    </LoadingOverlay>
  );
}

export default DetailsForm;
