import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./user";
import forumReducer from "./forum"
import navigatorReducer from "./navigator"

export default configureStore({
  reducer: {
    users: usersReducer,
    forum: forumReducer,
    navigator:navigatorReducer,
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});
