import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {doc,getDoc} from 'firebase/firestore'
import {db} from "../db"



export const getForum = createAsyncThunk('forum/getForum', async () => {
    const response = await getDoc(doc(db,"forum","forum"))
    return response.data()
  })


// export const getUsers = createAsyncThunk('users/getUsers', async (dispatch,getState) => {
//     return await fetch('https://jsonplaceholder.typicode.com/users')
//     .then((res)=>
//         res.json()
//     )
   
//   })

export const forumSlice = createSlice({
  name: "forum",
  initialState: {
    forum:"",
    status:null,
    index:''
  },
  extraReducers:{
    [getForum.pending]: (state,action) =>{
        state.status="loading"
    },
    [getForum.fulfilled]: (state,action) =>{
        state.status = "success";
        state.forum = action.payload
    },
    [getForum.rejected]:(state,action) =>{
        state.status = "failed"

    }
}
});





export default forumSlice.reducer;

